<template>
  <div class="technologyBox">
    <pcHeader />
    <div class="techbg">
      <img src="../assets/techobg.jpg" />
      <div class="banner-title">
        {{ $t("lang.technologyCenter.bannerTitle") }}
      </div>
    </div>
    <div class="techbox">
      <div class="title">{{ $t("lang.technologyCenter.titles") }}</div>
      <div class="solveWay" data-aos="fade-up">
        <div class="solvebox">
          <div class="solveTitle">
            <img src="../assets/con-img1-1.png" />
            <div class="item-title">
              <div class="titles" v-if="$i18n.locale === 'zh-CN'">解决方案</div>
              <span>IT SOLUTION</span>
            </div>
          </div>
          <div class="solveList">
            <div class="part">
              <div class="pic">
                <img src="../assets/icon7-1.png" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">基础网络</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >NETWORK</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon8-1.png" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">数据中心</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >DATACENTER</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon9-1.png" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">安全优化</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >SECURITY</span
              ><span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >OPTIMIZATION</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon10-1.png" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">视频协作</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >COLLABORATION</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon11-1.png" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">机房弱电</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >CABLING</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon12-1.png" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">无线增值</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >WIRELESS</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="solveWays" data-aos="fade-down">
        <div class="solvebox">
          <div class="solveTitle">
            <img src="../assets/con-img.png" class="showImg" />
            <img src="../assets/con-img-1.png" class="hideImg" />
            <div class="item-title">
              <div class="titles" v-if="$i18n.locale === 'zh-CN'">软件集成</div>
              <span>SOFTWARE INTEGRATION</span>
            </div>
          </div>
          <div class="solveList">
            <div class="part">
              <div class="pic">
                <img src="../assets/icon1.png" class="showImg" />
                <img src="../assets/icon1-1.png" class="hideImg" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">协作办公</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >COOPERATION</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon2.png" class="showImg" />
                <img src="../assets/icon2-1.png" class="hideImg" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">数据挖掘</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >DATA MINING</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon3.png" class="showImg" />
                <img src="../assets/icon3-1.png" class="hideImg" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">私有云</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >PRIVATE CLOUD</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon4.png" class="showImg" />
                <img src="../assets/icon4-1.png" class="hideImg" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">成绩云</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >RESULTS CLOUD</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon5.png" class="showImg" />
                <img src="../assets/icon5-1.png" class="hideImg" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">应用与网络管理</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >APM</span
              >
            </div>
            <div class="part">
              <div class="pic">
                <img src="../assets/icon6.png" class="showImg" />
                <img src="../assets/icon6-1.png" class="hideImg" />
              </div>
              <div v-if="$i18n.locale === 'zh-CN'">无线增值</div>
              <span
                :style="{
                  color: $i18n.locale === 'en-US' ? '#17518c' : '#B0B0B1',
                }"
                >WIRELESS</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
  data() {
    return {};
  },
  mounted() {
    AOS.init({
      duration: 1200,
    });
  },
  methods: {},
};
</script>
<style lang="scss">
.technologyBox {
  .techbg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .techbox {
    width: 1142px;
    margin: 0 auto;
    //margin-top: 120px;
    .title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin: 70px 0;
      margin-bottom: 50px;
    }
    .solveWay {
      background: url("../assets/tebg.jpg") no-repeat center center;
      margin-top: 60px;
      padding-bottom: 40px;
    }
    .solvebox {
      display: flex;
      //align-items: center;
      flex-direction: column;
      z-index: 9;
      .solveTitle {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .item-title {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        img {
          padding-top: 40px;
        }
        .titles {
          font-size: 20px;
          font-weight: bold;
        }
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .solveWays {
      background: #ffffff;
      cursor: pointer;
      margin-top: 40px;
      padding-bottom: 40px;
      margin-bottom: 40px;
      .part {
        background: #17518c !important;
        div,
        span {
          color: #ffffff !important;
        }
      }
      .solveTitle {
        color: #17518c;
      }
      .hideImg {
        display: none;
      }
      .showImg {
        display: block;
      }
    }
    .solveWays:hover {
      background: url("../assets/tebg.jpg") no-repeat center center;
      .hideImg {
        display: block;
      }
      .showImg {
        display: none;
      }
      .part {
        background: #ffffff !important;
        div,
        span {
          color: #17518c !important;
        }
      }
      .solveTitle {
        color: #ffffff;
      }
    }
    .solveList {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      .part {
        width: calc((100% - 245px) / 6);
        background: #ffffff;
        border-radius: 5px;
        height: 214px;
        margin-left: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .pic {
          margin-top: 30px;
          height: 77px;
        }
        div {
          font-size: 14px;
          font-weight: bold;
          color: #17518c;
          margin-top: 12px;
        }
        span {
          color: #b0b0b1;
          font-weight: bold;
          font-size: 12px;
        }
      }
      .part:last-child {
        margin-right: 35px;
      }
    }
  }
  .part:hover {
    animation: rotateFn 1s infinite;
    -webkit-animation: rotateFn 1s infinite;
  }
  @keyframes rotateFn {
    0% {
      transform: perspective(400px) rotateY(0deg);
      -webkit-transform: perspective(400px) rotateY(0deg);
    }
    100% {
      transform: perspective(400px) rotateY(360deg);
      -webkit-transform: perspective(400px) rotateY(360deg);
    }
  }
}
</style>
